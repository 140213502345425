@font-face
  font-family: "ProximaNova-Reg"
  src: url("../fonts/ProximaNova-Reg.ttf") format("truetype")
  font-display: swap

@font-face
  font-family: "ProximaNova-RegItalic"
  src: url("../fonts/ProximaNova-RegItalic.otf") format("opentype")
  font-display: swap

@font-face
  font-family: "ProximaNova-SemiBold"
  src: url("../fonts/ProximaNova-SemiBold.otf") format("opentype")
  font-display: swap

@font-face
  font-family: "ProximaNova-Bold"
  src: url("../fonts/ProximaNova-Bold.otf") format("opentype")
  font-display: swap

@font-face
  font-family: "ProximaNova-ExtraBold"
  src: url("../fonts/ProximaNova-ExtraBold.otf") format("opentype")
  font-display: swap

@font-face
  font-family: "KGPrimary-Italics"
  src: url("../fonts/KGPrimaryItalics.ttf") format("truetype")
  font-display: swap

@font-face
  font-family: "KGPrimary-ItalicsLined"
  src: url("../fonts/KGPrimaryItalicsLined.ttf") format("truetype")
  font-display: swap

body
  font-family: "ProximaNova-Reg",sans-serif
  background-color: #094a78
  height: 100%
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.animated.faster
  animation-duration: 200ms

.eng__navbar-custom
  height: 80px;
  background-color: #f26522;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  padding-left: 16%;
  margin: 0;
  padding-right: 16%;
  border-radius: unset;
  &:before,
  &:after
    display: none
  a
  	display: flex;
  	align-items: center;
    
    

@media (max-width: 991.98px)
  .eng__navbar-custom
    height: 60px

@media (max-width: 767.98px)
  .eng__navbar-custom
    padding-left: 20px
    padding-right: 15px

.eng__navbar-custom .eng__navbar-custom__logo
  height: 35px

@media (max-width: 991.98px)
  .eng__navbar-custom .eng__navbar-custom__logo
    height: 30px

.eng__navbar-custom .eng__navbar-custom__phone
  display: flex
  align-items center

.eng__navbar-custom .eng__navbar-custom__phone .eng__navbar-custom__phone-icon
  height: 60px
  margin-right: 5px

@media (max-width: 991.98px)
  .eng__navbar-custom .eng__navbar-custom__phone .eng__navbar-custom__phone-icon
    height: 45px

@media (max-width: 767.98px)
  .eng__navbar-custom .eng__navbar-custom__phone .eng__navbar-custom__phone-icon
    margin-right: 0

.eng__navbar-custom .eng__navbar-custom__phone .eng__navbar-custom__phone-details
  display: flex
  flex-direction: column
  // align-items: center
  justify-content: center

.eng__navbar-custom .eng__navbar-custom__phone .eng__navbar-custom__phone-details .phone-days
  font-size: 12px

@media (max-width: 991.98px)
  .eng__navbar-custom .eng__navbar-custom__phone .eng__navbar-custom__phone-details .phone-days
    font-size: 10px

.eng__navbar-custom .eng__navbar-custom__phone .eng__navbar-custom__phone-details .phone-number
  font-size: 24px
  font-family: "ProximaNova-Bold",sans-serif
  line-height: normal

@media (max-width: 991.98px)
  .eng__navbar-custom .eng__navbar-custom__phone .eng__navbar-custom__phone-details .phone-number
    font-size: 20px

.eng__navbar-custom .eng__navbar-custom__phone .eng__navbar-custom__phone-details .phone-number,
.eng__navbar-custom .eng__navbar-custom__phone .eng__navbar-custom__phone-details .phone-days
  color: #fff
  margin: 0

.eng__header-background-mobile
  position: fixed
  top: 0
  left: 0
  z-index: -1

.eng__main-background
  z-index: -1
  position: absolute
  width: 100%
  height: max-content

.eng__main-content
  display: flex
  flex-direction: column
  align-items: center

@media (max-width: 991.98px)
  .eng__main-content
    margin-top: 60px

@media (max-width: 767.98px)
  .eng__main-content
    margin-top: 50px
    padding: 0 25px

@media (max-width: 575.98px)
  .eng__main-content
    margin-top: 50px
    padding: 0 15px

.eng__main-content .eng__stepper-container
  width: 770px
  height: auto
  background-color: #fff
  margin-top: 60px

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container
    margin-top: 35px
    width: 520px
    height: auto

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container
    width: auto
    height: auto

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container
    margin-top: 20px
    width: auto
    min-height: 480px
    /* height:480px; helpme */

.eng__main-content .eng__stepper-container .eng__stepper__step
  position: relative
  // height: 100%

.eng__main-content .eng__stepper-container .eng__stepper__step .eng__cta-bubble
  position: absolute
  width: 210px
  left: -105px
  bottom: 70px

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .eng__cta-bubble
    width: 150px
    left: -44px

.eng__main-content .eng__stepper-container .eng__stepper__step .step-title
  font-size: 36px
  font-family: "ProximaNova-ExtraBold",sans-serif
  text-align: center
  margin: 20px 0
  color: #f26522

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-title
    font-size: 27px
    margin-bottom: 0

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-title
    font-size: 22px
    line-height: 28px
    margin-left: 40px
    margin-right: 40px
    margin-bottom: 10px

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-title
    font-size: 24px
    margin-left: 10px
    margin-right: 10px
    margin-bottom: 10px

.eng__main-content .eng__stepper-container .eng__stepper__step .step-subtitle
  font-size: 16px
  margin: 0 25%
  margin-bottom: 20px
  text-align: center
  color: #252d46

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-subtitle
    font-size: 14px
    margin: 0 15%
    margin-bottom: 10px

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-subtitle
    font-size: 16px
    margin: 0 32px
    margin-bottom: 10px

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-subtitle
    font-size: 13px
    margin: 0 15px
    margin-bottom: 10px

.eng__main-content .eng__stepper-container .eng__stepper__step .step-subtitle .step-subtitle-strong
  /* color:#f26522; */
  color: #000000
  font-weight: 800
  font-family: "ProximaNova-Bold",sans-serif

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-subtitle .step-subtitle-strong
    display: block

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container
  display: flex
  justify-content: center

/* @media(max-width: 400px){ */
/* helpme */
/* .eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container{ */
/* margin-bottom: 150px; */
/* } */
/* } */
@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container.horizontal-on-mobile
    flex-direction: column

  .eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container.horizontal-on-mobile .step-option-item .step-option-item__label
    width: auto
    height: auto
    flex-direction: row
    padding: 10px 15px
    margin: 8px 10px
    font-size: 16px
    box-shadow: 0 0 5px rgba(0,0,0,.12)
    text-align: left

  .eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container.horizontal-on-mobile .step-option-item .step-option-item__label .step-option-item__text
    margin-left: 15px

  .eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container.horizontal-on-mobile .step-option-item .step-option-item__label .step-option-item__image
    margin: 0

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container.horizontal-on-mobile .step-option-item .step-option-item__label
    font-size: 16px
    padding: 10px
    text-align: left

  .eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container.horizontal-on-mobile .step-option-item .step-option-item__label .step-option-item__text
    margin-left: 10px

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item
  margin: 0 10px

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label
  width: 120px
  height: 140px
  box-shadow: 0 1px 12px rgba(0,0,0,.12)
  border-radius: .25rem
  cursor: pointer
  text-transform: uppercase
  font-size: 14px
  text-align: center
  display: flex
  flex-direction: column
  justify-content: start
  align-items: center
  padding: 0 10px
  margin-bottom: 0
  margin-top: .5rem

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label:hover
  box-shadow: 0 2px 14px rgba(0,0,0,.19)

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label.error-state
  border: 1px solid #f26522

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label
    width: 100px
    height: 120px

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-option-item__text
  margin-bottom: 0
  line-height: normal
  color: #4f5567
  width: 100%

@media (min-width: 767.98px) and (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-option-item__text
    font-size: 12px!important
    /* helpme */

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-option-item__image
  width: 40px
  height: 40px
  border: 1px solid #e0e8f3
  border-radius: 50%
  margin-top: 35px
  margin-bottom: 15px
  background-repeat: no-repeat
  background-size: 15px
  background-position: center

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-option-item__image
    margin-top: 24px
    margin-bottom: 10px

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-option-item__image
    width: 30px
    height: 30px
    background-size: 12px

  .eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-option-item__image .step-1__A__image
    background-size: 8px

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-1__A__image
  background-size: 11px
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031218/electric_primary.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-1__B__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031258/water_drop_primary.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-1__C__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031222/eng_primary.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-3__A__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031233/home_primary.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-3__B__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031253/shopping_cart_primary.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-5__A__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031235/home_key_primary.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-5__B__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031228/home_mail_primary.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-6__A__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031158/check_primary.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-6__B__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031205/close_primary.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__radio
  display: none

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__radio:checked+.step-option-item__label
  background-color: #0a4c7b

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__radio:checked+.step-option-item__label .step-option-item__image
  border: none
  background-color: #f26522

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__radio:checked+.step-option-item__label .step-option-item__text
  color: #fff

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__radio:checked+.step-option-item__label .step-1__A__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031220/electric_white.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__radio:checked+.step-option-item__label .step-1__B__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031300/water_drop_white.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__radio:checked+.step-option-item__label .step-1__C__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031225/eng_white.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__radio:checked+.step-option-item__label .step-3__A__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031240/home_white.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__radio:checked+.step-option-item__label .step-3__B__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031256/shopping_cart_white.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__radio:checked+.step-option-item__label .step-5__A__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031238/home_key_white.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__radio:checked+.step-option-item__label .step-5__B__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031231/home_mail_white.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__radio:checked+.step-option-item__label .step-6__A__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031207/check_white.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__radio:checked+.step-option-item__label .step-6__B__image
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/05031213/close_white.png")

.eng__main-content .eng__stepper-container .eng__stepper__step .form-group-item
  margin: 29px auto 171px
  width: 350px

.eng__main-content .eng__stepper-container .eng__stepper__step .form-group-item .form-control
  height: 48px
  padding: 0.875rem 1.813rem

@media(max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .form-group-item
    width: 307px
    
.eng__main-content .eng__stepper-container .eng__stepper__step .eng__next-step
  display: flex
  justify-content: center
  margin-top: 30px

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .eng__next-step
    margin: 35px 20px

.eng__main-content .eng__stepper-container .eng__stepper__step .eng__next-step button
  border-radius: 5px
  background-color: #f26522
  color: #fff
  font-size: 18px
  padding: 10px 25px
  display: flex
  align-items: center
  box-shadow: 0 6px 8px rgba(242,101,34,.5)

.eng__main-content .eng__stepper-container .eng__stepper__step .eng__next-step button:hover
  box-shadow: 0 10px 18px rgba(242,101,34,.5)

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .eng__next-step button
    padding: 5px 30px
    font-size: 16px

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .eng__next-step button
    width: 100%
    padding: 15px 30px
    justify-content: center

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .eng__next-step button
    font-size: 18px

.eng__main-content .eng__stepper-container .eng__stepper__step .eng__next-step button i.fas
  margin-left: 15px

.eng__main-content .eng__stepper-container .eng__stepper__step .eng__prev-step
  display: flex
  justify-content: start
  margin-top: 15px

  &.j-center
    justify-content: center
    margin-top 50px

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .eng__prev-step
    margin-top: 10px

.eng__main-content .eng__stepper-container .eng__stepper__step .eng__prev-step button
  background-color: transparent
  color: #c8c8c8
  font-size: 14px
  display: flex
  align-items: center
  padding: 0

  &.skipStep
    font-size 16px 
    color #212529
    padding 10px 20px
    display flex
    align-items: center
    background: #e9ecef
    box-shadow: 0 6px 8px rgba(220,220,220,0.5);

    &:hover
      box-shadow: 0 10px 8px rgba(220,220,220,0.5);

    i.fas
      color inherit

.eng__main-content .eng__stepper-container .eng__stepper__step .eng__prev-step button:focus
  box-shadow: none

.eng__main-content .eng__stepper-container .eng__stepper__step .eng__prev-step button i.fas
  margin-right: 10px
  font-size: 14px
  color: #c8c8c8

.eng__main-content .eng__stepper-container .eng__stepper__step .step-form
  margin-right: 20px

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-form
    margin-right: 10px

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-form
    margin-right: 0

.eng__main-content .eng__stepper-container .eng__stepper__step .step-form .input-group.error-state
  border: 1px solid #f26522

.eng__main-content .eng__stepper-container .eng__stepper__step .step-form .input-group .input-group-prepend .input-group-text
  background-color: #fff
  /*helpme*/
  border-right: 0px

.eng__main-content .eng__stepper-container .eng__stepper__step .step-form .input-group .input-group-prepend .input-group-text i.fas
  color: #0a4c7b
  font-size: 24px

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-form .input-group .input-group-prepend .input-group-text i.fas
    font-size: 18px

.eng__main-content .eng__stepper-container .eng__stepper__step .step-form .input-group input
  border-left: none
  /* padding:24px; */
  /*helpme*/
  padding-left: 0
  font-size: 18px
  border-color: #dbdbdb
  background-color: #fff
  line-height: 27px
  height: 50px
  border-left: 0px

.eng__main-content .eng__stepper-container .eng__stepper__step .step-form .input-group input:focus
  box-shadow: none
  border-color: #dbdbdb

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-form .input-group input
    font-size: 14px
    /* padding:20px; */
    padding-left: 0
    line-height: 27px
    height: 50px
    border-left: 0px

.eng__main-content .eng__stepper-container .eng__stepper__step .step-form .input-group .step-form-info
  position: absolute
  right: 6px
  top: -2px
  cursor: pointer
  z-index: 3

.eng__main-content .eng__stepper-container .eng__stepper__step .step-form .input-group .step-form-info i.fas
  font-size: 10px
  color: #b2b2b2

.eng__main-content .eng__stepper-container .eng__stepper__step .step-form .step-info-message
  text-align: center
  color: #f26522
  font-size: 12px
  margin-top: 0
  margin-bottom: .25rem

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-form .step-info-message
    font-size: 11px

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step .step-form .step-info-message
    font-size: 12px

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-1 .step-title
    margin-left: 30px
    margin-right: 30px

@media (min-width: 768px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-1 .eng__next-step
    margin-bottom: 40px

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-1 .eng__next-step
    /* position:absolute; */
    bottom: 0
    width: 100%
    margin: 0
    margin-top: 35px
    padding: 5px 20px

  .eng__main-content .eng__stepper-container .eng__stepper__step.step-1 .eng__next-step button
    padding: 10px

@media (min-width: 576px)and (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .eng__next-step
    /* position:absolute; */
    bottom: 60px
    width: 100%
    margin: 0
    left: 0
    padding: 0 30px

  #step2 .eng__next-step
    position: absolute!important

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .eng__next-step
    /* position:absolute; */
    bottom: 0
    width: 100%
    margin: 0
    margin-bottom: 45px
    left: 0
    padding: 5px 20px

  #step2 .eng__next-step
    position: absolute!important

  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .eng__next-step button
    padding: 10px

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .eng__prev-step
    position: absolute
    bottom: 20px
    width: 100%
    margin: 0
    left: 0
    padding: 0 30px

.eng__main-content .eng__stepper-container .eng__stepper__step.step-2,
.eng__main-content .eng__stepper-container .eng__stepper__step.step-8
  display: flex

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8
    flex-direction: column-reverse

.eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .left-side,
.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .left-side
  width: 50%
  padding: 35px 40px
  display: flex
  justify-content: center
  align-items: center

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .left-side,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .left-side
    width: auto
    height: auto
    margin-bottom: 120px
    padding-top: 20px

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .left-side,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .left-side
    padding-top: 0

.eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .left-side img,
.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .left-side img
  width: 260px
  height: 243px

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .left-side img,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .left-side img
    width: 207px
    height: 194px

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .left-side img,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .left-side img
    width: 149px
    height: 138px

.eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side,
.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side
  height: 100%
  width: 50%
  padding: 35px 40px
  box-shadow: inset 0 0 50px 0 rgba(0,0,0,.075)
  padding-left: 30px

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side
    padding: 20px

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side
    width: auto
    height: auto
    box-shadow: none

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side
    padding: 10px 20px

.eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .step-title,
.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .step-title
  text-align: left
  font-size: 30px
  margin-bottom: 10px
  margin-top: 10px

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .step-title,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .step-title
    font-size: 24px
    line-height: 30px

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .step-title,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .step-title
    text-align: center
    margin-left: 0
    margin-right: 0
    font-size: 28px

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .step-title,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .step-title
    font-size: 24px

.eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .step-subtitle,
.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .step-subtitle
  margin-left: 0
  margin-right: 15%
  margin-bottom: 20px
  text-align: left

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .step-subtitle,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .step-subtitle
    margin-bottom: 15px
    font-size: 14px

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .step-subtitle,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .step-subtitle
    text-align: center
    margin-left: 10px
    margin-right: 10px
    font-size: 13px

.eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .eng__next-step,
.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .eng__next-step
  margin-top: 30px
  justify-content: start
  display: block
  /*helpme*/

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .eng__next-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .eng__next-step
    margin-top: 10px

@media (min-width: 576px)and (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .eng__next-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .eng__next-step
    width: 100%
    margin: 0
    padding: 0 30px

.eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .eng__next-step button,
.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .eng__next-step button
  padding: 10px 50px

@media (max-width: 991.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .eng__next-step button,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .eng__next-step button
    padding: 5px 40px

@media (min-width: 576px)and (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .eng__next-step button,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .eng__next-step button
    padding: 10px
    font-size: 22px

@media (min-width: 576px)and (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .eng__prev-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .eng__prev-step
    width: 100%
    margin: 0
    padding: 0 30px

.eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .eng__prev-step button,
.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .eng__prev-step button
  width: 100%

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .right-side .eng__prev-step button,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .eng__prev-step button
    justify-content: center

.eng__main-content .eng__stepper-container .eng__stepper__step.step-3 .eng__next-step,
.eng__main-content .eng__stepper-container .eng__stepper__step.step-5 .eng__next-step,
.eng__main-content .eng__stepper-container .eng__stepper__step.step-6 .eng__next-step
  margin-top: 60px

@media (min-width: 576px)and (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-3 .eng__next-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-5 .eng__next-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-6 .eng__next-step
    margin-bottom: 0

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-3 .eng__next-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-5 .eng__next-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-6 .eng__next-step
    /* position:absolute; */
    bottom: 0
    width: 100%
    margin: 0
    margin-top: 70px
    padding: 5px 20px

  .eng__main-content .eng__stepper-container .eng__stepper__step.step-3 .eng__next-step button,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-5 .eng__next-step button,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-6 .eng__next-step button
    padding: 10px

@media (min-width: 768px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-3 .eng__next-step button,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-5 .eng__next-step button,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-6 .eng__next-step button
    padding: 10px 60px

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-3 .eng__prev-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-4 .eng__prev-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-5 .eng__prev-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-6 .eng__prev-step
    margin-bottom: 20px

@media (min-width: 768px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-3 .eng__prev-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-4 .eng__prev-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-5 .eng__prev-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-6 .eng__prev-step
    margin-bottom: 40px

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-3 .eng__prev-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-4 .eng__prev-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-5 .eng__prev-step,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-6 .eng__prev-step
    /* position:absolute; */
    bottom: 0
    width: 100%
    margin: 0
    margin-bottom: 20px
    padding: 5px 20px

  .eng__main-content .eng__stepper-container .eng__stepper__step.step-3 .eng__prev-step button,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-4 .eng__prev-step button,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-5 .eng__prev-step button,
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-6 .eng__prev-step button
    padding: 10px

.eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .step-subtitle
  margin-left: 12%
  margin-right: 12%

.eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__step-loader
  display: block
  justify-content: center
  padding: 20px 0px

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__step-loader
    margin-top: 30px

.eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__step-loader .loader
  margin: 60px auto
  font-size: 17px
  animation: load4 1.1s infinite linear

.eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__benefits
  margin-top: 35px

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__benefits
    margin-bottom: 30px

@media (min-width: 768px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__benefits
    margin-bottom: 40px

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__benefits
    margin-top: 20px

.eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__benefits .row
  margin: 0

.eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__benefits .eng__benefits-item
  display: flex
  justify-content: center
  align-items: center

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__benefits .eng__benefits-item
    justify-content: start
    margin: 8px 20px
    margin-right: 0
    margin-left: 40px

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__benefits .eng__benefits-item
    margin: 6px 20px
    margin-left: 35px

.eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__benefits .eng__benefits-item .benefit-image
  width: 40px
  height: 40px
  background-color: #f26522
  border-radius: 50%
  background-repeat: no-repeat
  background-size: 20px
  background-position: center

.eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__benefits .eng__benefits-item .benefit-image.image-1
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2020/03/30144511/clock_white.png")

.eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__benefits .eng__benefits-item .benefit-image.image-2
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2020/03/30144514/save_white.png")

.eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__benefits .eng__benefits-item .benefit-image.image-3
  background-image: url("https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2020/03/30144512/docs_white.png")

.eng__main-content .eng__stepper-container .eng__stepper__step.step-7 .eng__benefits .eng__benefits-item .benefit-text
  margin-bottom: 0
  margin-left: 15px
  font-size: 14px

.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .step-form .input-group .input-group-prepend .input-group-text i.fas
  font-size: 18px

.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .step-form .form-check
  padding-left: 5px
  display: flex
  align-items: center

.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .step-form .form-check .form-control
  width: 25px
  height: 25px

.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .step-form .form-check .form-control:focus
  box-shadow: none

.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .step-form .form-check .form-check-label
  margin-left: 10px
  font-size: 12px

@media (max-width: 767.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .eng__next-step
    margin-bottom: 10px

@media (max-width: 575.98px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .eng__next-step
    margin: 0

.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .eng__next-step button
  padding: 10px 20px

@media (min-width: 768px)
  .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .eng__prev-step button
    justify-content: start

.eng__main-content .eng__feedback-ratings
  width: 770px
  height: 215px
  background-color: #fff
  margin-top: 40px
  margin-bottom: 40px
  display: flex

@media (max-width: 991.98px)
  .eng__main-content .eng__feedback-ratings
    width: 520px
    height: 190px
    margin-top: 25px

@media (max-width: 767.98px)
  .eng__main-content .eng__feedback-ratings
    width: auto
    height: auto
    flex-direction: column

.eng__main-content .eng__feedback-ratings .eng__feedback
  height: 100%
  width: 50%
  padding: 35px 40px

@media (max-width: 991.98px)
  .eng__main-content .eng__feedback-ratings .eng__feedback
    padding: 20px

@media (max-width: 767.98px)
  .eng__main-content .eng__feedback-ratings .eng__feedback
    width: auto
    display: flex
    flex-direction: column
    align-items: center

.eng__main-content .eng__feedback-ratings .eng__feedback .eng__feedback__quote-image
  width: 35px

@media (max-width: 991.98px)
  .eng__main-content .eng__feedback-ratings .eng__feedback .eng__feedback__quote-image
    width: 30px

@media (max-width: 767.98px)
  .eng__main-content .eng__feedback-ratings .eng__feedback .eng__feedback__quote-image
    margin-top: 10px
    width: 25px

.eng__main-content .eng__feedback-ratings .eng__feedback .eng__feedback__quote-text
  font-size: 16px
  line-height: normal
  margin: 20px 30px 10px 0
  color: #252d46

@media (max-width: 991.98px)
  .eng__main-content .eng__feedback-ratings .eng__feedback .eng__feedback__quote-text
    font-size: 14px
    margin: 10px
    margin-left: 0
    line-height: inherit

@media (max-width: 767.98px)
  .eng__main-content .eng__feedback-ratings .eng__feedback .eng__feedback__quote-text
    text-align: center
    font-size: 20px

@media (max-width: 575.98px)
  .eng__main-content .eng__feedback-ratings .eng__feedback .eng__feedback__quote-text
    font-size: 16px

.eng__main-content .eng__feedback-ratings .eng__feedback .eng__feedback__quote-writer
  display: flex
  align-items: center

@media (max-width: 767.98px)
  .eng__main-content .eng__feedback-ratings .eng__feedback .eng__feedback__quote-writer
    margin-top: 20px

.eng__main-content .eng__feedback-ratings .eng__feedback .eng__feedback__quote-writer .eng__feedback__quote-separator-image
  width: 21px
  margin-right: 15px

.eng__main-content .eng__feedback-ratings .eng__feedback .eng__feedback__quote-writer .eng__feedback__quote-writer-name
  margin-bottom: 0
  font-family: "ProximaNova-Bold",sans-serif
  font-size: 24px

.eng__main-content .eng__feedback-ratings .eng__ratings
  height: 100%
  width: 50%
  padding: 35px 40px
  box-shadow: inset 0 0 50px 0 rgba(0,0,0,.075)

@media (max-width: 991.98px)
  .eng__main-content .eng__feedback-ratings .eng__ratings
    padding-left: 20px
    padding-top: 30px
    box-shadow: inset 0 0 25px 0 rgba(0,0,0,.075)

@media (max-width: 767.98px)
  .eng__main-content .eng__feedback-ratings .eng__ratings
    width: auto
    display: flex
    flex-direction: column
    align-items: center
    box-shadow: inset 0px 2px 14px 0 rgba(0,0,0,.075)

.eng__main-content .eng__feedback-ratings .eng__ratings .eng__ratings-title
  font-family: "ProximaNova-Bold",sans-serif
  font-size: 24px

@media (max-width: 991.98px)
  .eng__main-content .eng__feedback-ratings .eng__ratings .eng__ratings-title
    font-size: 21px

@media (max-width: 767.98px)
  .eng__main-content .eng__feedback-ratings .eng__ratings .eng__ratings-title
    font-family: "ProximaNova-Reg",sans-serif

.eng__main-content .eng__feedback-ratings .eng__ratings .eng__ratings-feefo-logo
  width: 135px
  margin-top: 5px

@media (max-width: 991.98px)
  .eng__main-content .eng__feedback-ratings .eng__ratings .eng__ratings-feefo-logo
    width: 116px

@media (max-width: 767.98px)
  .eng__main-content .eng__feedback-ratings .eng__ratings .eng__ratings-feefo-logo
    margin-top: 20px
    width: 230px

@media (max-width: 575.98px)
  .eng__main-content .eng__feedback-ratings .eng__ratings .eng__ratings-feefo-logo
    width: 184px

.eng__main-content .eng__feedback-ratings .eng__ratings .eng__ratings-five-stars
  width: 183px
  margin-top: 20px

@media (max-width: 991.98px)
  .eng__main-content .eng__feedback-ratings .eng__ratings .eng__ratings-five-stars
    width: 140px

@media (max-width: 767.98px)
  .eng__main-content .eng__feedback-ratings .eng__ratings .eng__ratings-five-stars
    width: 270px
    margin-top: 30px

@media (max-width: 575.98px)
  .eng__main-content .eng__feedback-ratings .eng__ratings .eng__ratings-five-stars
    width: 183px

.eng__cta
  background-color: #fff
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  padding: 20px

@media (max-width: 767.98px)
  .eng__cta
    flex-direction: column
    padding: 40px

.eng__cta .eng__cta-text
  margin-bottom: 0
  font-size: 21px
  color: #252d46
  margin-right: 30px

@media (max-width: 991.98px)
  .eng__cta .eng__cta-text
    font-size: 18px

@media (max-width: 767.98px)
  .eng__cta .eng__cta-text
    font-size: 24px
    margin-right: 0
    margin-bottom: 20px

@media (max-width: 575.98px)
  .eng__cta .eng__cta-text
    font-size: 18px

.eng__cta .eng__cta-button
  border-radius: 5px
  background-color: #f26522
  color: #fff
  font-size: 18px
  padding: 10px 25px
  display: flex
  align-items: center
  box-shadow: none

.eng__cta .eng__cta-button:hover
  box-shadow: 0 10px 18px rgba(242,101,34,.5)

@media (max-width: 767.98px)
  .eng__cta .eng__cta-button
    width: 100%
    padding: 15px 25px
    justify-content: center
    box-shadow: 0 5px 12px rgba(242,101,34,.5)

.eng__cta .eng__cta-button i.fas
  margin-left: 15px

.eng__footer
  background-color: #094a78
  padding: 40px 16%

@media (max-width: 991.98px)
  .eng__footer
    padding: 24px 12%

@media (max-width: 767.98px)
  .eng__footer
    padding: 24px 60px

@media (max-width: 575.98px)
  .eng__footer
    padding: 15px 30px

.eng__footer .eng__footer-links
  display: flex
  justify-content: center
  border-bottom: 1px solid #07395f
  padding-bottom: 20px

@media (max-width: 767.98px)
  .eng__footer .eng__footer-links
    padding-top: 20px

.eng__footer .eng__footer-links a
  color: #fff
  margin: 0 30px

@media (max-width: 767.98px)
  .eng__footer .eng__footer-links a
    font-size: 20px
    text-transform: uppercase
    margin: 0

  .eng__footer .eng__footer-links a:first-child
    margin-right: 10px

  .eng__footer .eng__footer-links a:last-child
    margin-left: 10px

@media (max-width: 575.98px)
  .eng__footer .eng__footer-links a
    font-size: 15px

.eng__footer .eng__footer-text
  margin-bottom: 50px
  padding-top: 20px
  font-size: 14px
  color: #fff
  text-align: center

@media (max-width: 991.98px)
  .eng__footer .eng__footer-text
    margin-bottom: 20px

@media (max-width: 575.98px)
  .eng__footer .eng__footer-text
    font-size: 11px

/* added modify*/
.autocomplete-suggestions
  text-align: left
  cursor: default
  border: 1px solid #ccc
  border-top: 0
  background: #fff
  -webkit-box-shadow: -1px 1px 3px rgba(0,0,0,0.1)
  box-shadow: -1px 1px 3px rgba(0,0,0,0.1)
  position: absolute
  display: none
  z-index: 9999
  max-height: 254px
  overflow: hidden
  overflow-y: auto
  margin-left: -10px

.autocomplete-suggestions .autocomplete-suggestion
  position: relative
  padding: 10px
  line-height: 23px
  white-space: nowrap
  overflow: hidden
  font-size: 0.9em
  color: #888
  cursor: pointer

.autocomplete-suggestions .autocomplete-suggestion b
  color: #fb9943

.autocomplete-suggestions .autocomplete-suggestion.selected
  background: #f0f0f0

.tooltip
  opacity: 1!important

.shortform .formstep
  display: block

.loader
  color: #f26522
  font-size: 20px
  margin: 100px auto
  width: 1em
  height: 1em
  border-radius: 50%
  position: relative
  text-indent: -9999em
  -webkit-animation: load4 1.3s infinite linear
  animation: load4 1.3s infinite linear
  -webkit-transform: translateZ(0)
  -ms-transform: translateZ(0)
  transform: translateZ(0)

@keyframes load4
  0%, 100%
    box-shadow: 0 -3em 0 .2em,2em -2em 0 0em,3em 0 0 -1em,2em 2em 0 -1em,0 3em 0 -1em,-2em 2em 0 -1em,-3em 0 0 -1em,-2em -2em 0 0
  12.5%
    box-shadow: 0 -3em 0 0,2em -2em 0 .2em,3em 0 0 0,2em 2em 0 -1em,0 3em 0 -1em,-2em 2em 0 -1em,-3em 0 0 -1em,-2em -2em 0 -1em
  25%
    box-shadow: 0 -3em 0 -0.5em,2em -2em 0 0,3em 0 0 .2em,2em 2em 0 0,0 3em 0 -1em,-2em 2em 0 -1em,-3em 0 0 -1em,-2em -2em 0 -1em
  37.5%
    box-shadow: 0 -3em 0 -1em,2em -2em 0 -1em,3em 0em 0 0,2em 2em 0 .2em,0 3em 0 0em,-2em 2em 0 -1em,-3em 0em 0 -1em,-2em -2em 0 -1em
  50%
    box-shadow: 0 -3em 0 -1em,2em -2em 0 -1em,3em 0 0 -1em,2em 2em 0 0em,0 3em 0 .2em,-2em 2em 0 0,-3em 0em 0 -1em,-2em -2em 0 -1em
  62.5%
    box-shadow: 0 -3em 0 -1em,2em -2em 0 -1em,3em 0 0 -1em,2em 2em 0 -1em,0 3em 0 0,-2em 2em 0 .2em,-3em 0 0 0,-2em -2em 0 -1em
  75%
    box-shadow: 0em -3em 0 -1em,2em -2em 0 -1em,3em 0em 0 -1em,2em 2em 0 -1em,0 3em 0 -1em,-2em 2em 0 0,-3em 0em 0 .2em,-2em -2em 0 0
  87.5%
    box-shadow: 0em -3em 0 0,2em -2em 0 -1em,3em 0 0 -1em,2em 2em 0 -1em,0 3em 0 -1em,-2em 2em 0 0,-3em 0em 0 0,-2em -2em 0 .2em
@keyframes load4
  0%, 100%
    box-shadow: 0 -3em 0 .2em,2em -2em 0 0em,3em 0 0 -1em,2em 2em 0 -1em,0 3em 0 -1em,-2em 2em 0 -1em,-3em 0 0 -1em,-2em -2em 0 0
  12.5%
    box-shadow: 0 -3em 0 0,2em -2em 0 .2em,3em 0 0 0,2em 2em 0 -1em,0 3em 0 -1em,-2em 2em 0 -1em,-3em 0 0 -1em,-2em -2em 0 -1em
  25%
    box-shadow: 0 -3em 0 -0.5em,2em -2em 0 0,3em 0 0 .2em,2em 2em 0 0,0 3em 0 -1em,-2em 2em 0 -1em,-3em 0 0 -1em,-2em -2em 0 -1em
  37.5%
    box-shadow: 0 -3em 0 -1em,2em -2em 0 -1em,3em 0em 0 0,2em 2em 0 .2em,0 3em 0 0em,-2em 2em 0 -1em,-3em 0em 0 -1em,-2em -2em 0 -1em
  50%
    box-shadow: 0 -3em 0 -1em,2em -2em 0 -1em,3em 0 0 -1em,2em 2em 0 0em,0 3em 0 .2em,-2em 2em 0 0,-3em 0em 0 -1em,-2em -2em 0 -1em
  62.5%
    box-shadow: 0 -3em 0 -1em,2em -2em 0 -1em,3em 0 0 -1em,2em 2em 0 -1em,0 3em 0 0,-2em 2em 0 .2em,-3em 0 0 0,-2em -2em 0 -1em
  75%
    box-shadow: 0em -3em 0 -1em,2em -2em 0 -1em,3em 0em 0 -1em,2em 2em 0 -1em,0 3em 0 -1em,-2em 2em 0 0,-3em 0em 0 .2em,-2em -2em 0 0
  87.5%
    box-shadow: 0em -3em 0 0,2em -2em 0 -1em,3em 0 0 -1em,2em 2em 0 -1em,0 3em 0 -1em,-2em 2em 0 0,-3em 0em 0 0,-2em -2em 0 .2em
.tooltip
  position: absolute
  z-index: 1070
  display: block
  margin: 0
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
  font-style: normal
  font-weight: 400
  line-height: 1.5
  text-align: left
  text-align: start
  text-decoration: none
  text-shadow: none
  text-transform: none
  letter-spacing: normal
  word-break: normal
  word-spacing: normal
  white-space: normal
  line-break: auto
  font-size: .875rem
  word-wrap: break-word
  opacity: 0

.tooltip.in
  opacity: 0.9

.tooltip.top
  padding: 5px 0
  margin-top: -3px

.tooltip.right
  padding: 0 5px
  margin-left: 3px

.tooltip.bottom
  padding: 5px 0
  margin-top: 3px

.tooltip.left
  padding: 0 5px
  margin-left: -3px

.tooltip-inner
  max-width: 200px
  padding: 3px 8px
  color: #fff
  text-align: center
  background-color: #000
  border-radius: 4px

.tooltip-arrow
  position: absolute
  width: 0
  height: 0
  border-color: transparent
  border-style: solid

.tooltip.top .tooltip-arrow
  bottom: 0
  left: 50%
  margin-left: -5px
  border-width: 5px 5px 0
  border-top-color: #000

.tooltip.top-left .tooltip-arrow
  right: 5px
  bottom: 0
  margin-bottom: -5px
  border-width: 5px 5px 0
  border-top-color: #000

.tooltip.top-right .tooltip-arrow
  bottom: 0
  left: 5px
  margin-bottom: -5px
  border-width: 5px 5px 0
  border-top-color: #000

.tooltip.right .tooltip-arrow
  top: 50%
  left: 0
  margin-top: -5px
  border-width: 5px 5px 5px 0
  border-right-color: #000

.tooltip.left .tooltip-arrow
  top: 50%
  right: 0
  margin-top: -5px
  border-width: 5px 0 5px 5px
  border-left-color: #000

.tooltip.bottom .tooltip-arrow
  top: 0
  left: 50%
  margin-left: -5px
  border-width: 0 5px 5px
  border-bottom-color: #000

.tooltip.bottom-left .tooltip-arrow
  top: 0
  right: 5px
  margin-top: -5px
  border-width: 0 5px 5px
  border-bottom-color: #000

.tooltip.bottom-right .tooltip-arrow
  top: 0
  left: 5px
  margin-top: -5px
  border-width: 0 5px 5px
  border-bottom-color: #000

.popover
  position: absolute
  top: 0
  left: 0
  z-index: 1060
  display: none
  max-width: 276px
  padding: 1px
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 1.42857143
  text-align: left
  text-align: start
  text-decoration: none
  text-shadow: none
  text-transform: none
  letter-spacing: normal
  word-break: normal
  word-spacing: normal
  word-wrap: normal
  white-space: normal
  background-color: #fff
  -webkit-background-clip: padding-box
  -webkit-background-clip: padding
  -moz-background-clip: padding
  background-clip: padding-box
  border: 1px solid #ccc
  border: 1px solid rgba(0,0,0,0.2)
  border-radius: 6px
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2)
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2)
  box-shadow: 0 5px 10px rgba(0,0,0,0.2)
  line-break: auto

.popover.top
  margin-top: -10px

.popover.right
  margin-left: 10px

.popover.bottom
  margin-top: 10px

.popover.left
  margin-left: -10px

.popover-title
  padding: 8px 14px
  margin: 0
  font-size: 14px
  background-color: #f7f7f7
  border-bottom: 1px solid #ebebeb
  border-radius: 5px 5px 0 0

.popover-content
  padding: 9px 14px

.popover>.arrow,
.popover>.arrow:after
  position: absolute
  display: block
  width: 0
  height: 0
  border-color: transparent
  border-style: solid

.popover>.arrow
  border-width: 11px

.popover>.arrow:after
  content: ""
  border-width: 10px

.popover.top>.arrow
  bottom: -11px
  left: 50%
  margin-left: -11px
  border-top-color: #999
  border-top-color: rgba(0,0,0,0.25)
  border-bottom-width: 0

.popover.top>.arrow:after
  bottom: 1px
  margin-left: -10px
  content: " "
  border-top-color: #fff
  border-bottom-width: 0

.popover.right>.arrow
  top: 50%
  left: -11px
  margin-top: -11px
  border-right-color: #999
  border-right-color: rgba(0,0,0,0.25)
  border-left-width: 0

.popover.right>.arrow:after
  bottom: -10px
  left: 1px
  content: " "
  border-right-color: #fff
  border-left-width: 0

.popover.bottom>.arrow
  top: -11px
  left: 50%
  margin-left: -11px
  border-top-width: 0
  border-bottom-color: #999
  border-bottom-color: rgba(0,0,0,0.25)

.popover.bottom>.arrow:after
  top: 1px
  margin-left: -10px
  content: " "
  border-top-width: 0
  border-bottom-color: #fff

.popover.left>.arrow
  top: 50%
  right: -11px
  margin-top: -11px
  border-right-width: 0
  border-left-color: #999
  border-left-color: rgba(0,0,0,0.25)

.popover.left>.arrow:after
  right: 1px
  bottom: -10px
  content: " "
  border-right-width: 0
  border-left-color: #fff

#loader
  position: fixed
  background: rgba(255,255,255,0.9) url("../images/preloader-withtext.gif") no-repeat center
  z-index: 99999
  left: 0
  right: 0
  top: 0
  bottom: 0
  opacity: 0
  display: none
  visibility: hidden
  -webkit-transition: all 1s linear
  -moz-transition: all 1s linear
  -o-transition: all 1s linear
  -ms-transition: all 1s linear
  transition: all 1s linear
  pointer-events: none

#loader.show
  display: block
  visibility: visible
  opacity: 1
  -ms-filter: none
  filter: none

.invalidpostcode-error
  margin-top: -40px!important
  margin-left: -30px

@media (max-width: 991.98px)
  .invalidpostcode-error
    margin-left: -70px

@media (max-width: 767.98px)
  .invalidpostcode-error
    margin-left: 40px

@media (max-width: 575.98px)
  .invalidpostcode-error
    margin-left: 5%

@media (max-width: 400.98px)
  .invalidpostcode-error
    margin-left: -5%

.phone-number
  a 
    text-decoration none
    color inherit